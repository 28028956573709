import {
  DashboardOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  FileSyncOutlined,
  LaptopOutlined,
  FileOutlined, WechatOutlined, DownloadOutlined,
  SearchOutlined, StarOutlined, ApiOutlined, HddOutlined, CrownOutlined,
  ReadOutlined, ContainerOutlined, ToolOutlined, GoldOutlined, LockOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'general',
    path: `${APP_PREFIX_PATH}`,
    title: 'sidenav.hidden',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-search',
        path: `${APP_PREFIX_PATH}/search`,
        title: 'title.search',
        icon: SearchOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboards-submit-founds',
        path: `${APP_PREFIX_PATH}/submit`,
        title: 'sidenav.hashlists.submit_founds',
        icon: CloudUploadOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboards-hashlists',
        path: `${APP_PREFIX_PATH}/hashlists`,
        title: 'sidenav.hashlists',
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'dashboards-hashlists-user',
            path: `${APP_PREFIX_PATH}/hashlists/user`,
            title: 'sidenav.hashlists.user',
            icon: FileOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'dashboards-hashlists-official',
            path: `${APP_PREFIX_PATH}/hashlists/official`,
            title: 'sidenav.hashlists.official',
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'dashboards-hashlist-premium',
            path: `${APP_PREFIX_PATH}/hashlists/premium`,
            title: 'sidenav.hashlists.premium',
            icon: GoldOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'dashboards-downloads',
            path: `${APP_PREFIX_PATH}/hashlists/combined_lists`,
            title: 'sidenav.combined_left_lists',
            icon: DownloadOutlined,
            breadcrumb: false,
            submenu: []
          },
        ]
      },
      {
        key: 'resources',
        path: `${APP_PREFIX_PATH}/resources`,
        title: 'sidenav.research_resources',
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'research-hashmob',
            path: `${APP_PREFIX_PATH}/resources/hashmob`,
            title: 'sidenav.resources.hashmob',
            icon: HddOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'research-wordlists',
            path: `${APP_PREFIX_PATH}/resources/wordlists`,
            title: 'sidenav.resources.wordlists_other',
            icon: HddOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'research-rules',
            path: `${APP_PREFIX_PATH}/resources/rules`,
            title: 'sidenav.resources.rules_other',
            icon: HddOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'writeups',
            path: `/writeups`,
            title: 'sidenav.resources.writeups',
            icon: LaptopOutlined,
            breadcrumb: false,
            submenu: []
          },
        ]
      },
      {
        key: 'tools',
        path: `${APP_PREFIX_PATH}/tools`,
        title: 'sidenav.tools',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'verifier',
            path: `${APP_PREFIX_PATH}/verifier`,
            title: 'sidenav.verifier',
            icon: FileDoneOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'passwords',
            path: `${APP_PREFIX_PATH}/passwords`,
            title: 'sidenav.passwords',
            icon: LockOutlined,
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'research-programs',
            path: `${APP_PREFIX_PATH}/resources/utilities`,
            title: 'sidenav.research.programs',
            icon: ContainerOutlined,
            breadcrumb: false,
            submenu: []
          }
        ]
      },
      {
        key: 'research-api-information',
        path: `${APP_PREFIX_PATH}/resources/information`,
        title: 'sidenav.research.api_information',
        icon: ApiOutlined,
        breadcrumb: false,
        submenu: []
      },
    ],
  },
  {
    key: 'other',
    path: `${APP_PREFIX_PATH}/`,
    title: 'other',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboards-leaderboard',
        path: `${APP_PREFIX_PATH}/leaderboard`,
        title: 'sidenav.leaderboard',
        icon: CrownOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboards-discord',
        path: `${APP_PREFIX_PATH}/discord`,
        title: 'sidenav.join_discord',
        icon: WechatOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'dashboards-patreon',
        path: `${APP_PREFIX_PATH}/patreon`,
        title: 'sidenav.patreon',
        icon: StarOutlined,
        breadcrumb: false,
        submenu: []
      },
    ]
  }
]

const appsNavTree = []

const componentsNavTree = []

const docsNavTree = []

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...componentsNavTree,
  ...docsNavTree
]

export default navigationConfig;
