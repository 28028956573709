import React from 'react';
import {withRouter} from 'react-router-dom';
import ReactGA from "react-ga";


class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);