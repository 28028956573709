import React, {useEffect, useState, localStorage} from "react";
import {Route, Switch, Redirect, withRouter,} from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import {userdataAtom} from "../state_atoms";
import {useRecoilState} from "recoil";


export const Views = (props) => {
    const { locale, token, location, direction } = props;
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [userLocale, setUserLocale] = useState('en');

    const currentAppLocale = AppLocale[userLocale];
    useBodyClass(`dir-${direction}`);
    return (
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
                <Switch>
                    {/*<Route path={AUTH_PREFIX_PATH}>*/}
                    {/*  <AuthLayout direction={direction} />*/}
                    {/*</Route>*/}
                    <Route path={AUTH_PREFIX_PATH}>
                        <AuthLayout direction={direction}/>
                    </Route>
                    <Route path={APP_PREFIX_PATH}>
                        <AppLayout direction={direction} location={location} userdata={userData} userlocale={userLocale}/>
                    </Route>
                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}


const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } =  theme;
  const { token } = auth;
  return { locale, direction, token }
};

export default withRouter(connect(mapStateToProps)(Views));
