import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import ReactGA from "react-ga";

const Other = lazy(() => import(`./other`));

// var doABarrelRoll = function(){var a="-webkit-",b='transform:rotate(1turn);',c='transition:4s;';document.head.innerHTML+='<style>body{'+a+b+a+c+b+c}

export const AppViews = () => {
    // const playMusic = () => {
    //     var audio = document.getElementById("myAudio");
    //     audio.volume = 0.15;
    //     audio.loop = true;
    //     audio.play();
    // };
    // const location = useLocation();
    // useEffect(() => {
    //     playMusic();
    // }, [location.key]);

    // useEffect(() => {
    //     doABarrelRoll();
    // }, []);

  return (
    <Suspense fallback={<Loading cover="content"/>}>
        {/*<audio id={'myAudio'} src="/rick.mp3"/>*/}
        <Switch>
            <Route path={`${APP_PREFIX_PATH}/hashlists`} component={lazy(() => import(`./hashlists`))} />
            <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
            <Route path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./user`))} />
            <Route path={`${APP_PREFIX_PATH}/resources`} component={lazy(() => import(`./resources`))} />
            <Route path={`${APP_PREFIX_PATH}/passwords`} component={lazy(() => import(`./passwords`))} />
            <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
            <Route path={`${APP_PREFIX_PATH}/verifier`} component={lazy(() => import(`views/app-views/verifier`))} />
            <Route path={`${APP_PREFIX_PATH}/discord`} component={() => {
                ReactGA.outboundLink(
                    {
                        label: 'Visited Discord Join Link.'
                    },
                    function () {
                        const newWindow = window.open('https://discord.gg/TwetN9eegn', '_blank', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                        window.location.replace("/");
                    }
                );
            }}/>
            <Route path={`${APP_PREFIX_PATH}/writeups`} component={() => {
                ReactGA.outboundLink(
                    {
                        label: 'Visited Writeups Link.'
                    },
                    function () {
                        const newWindow = window.open('https://hashmob.net/writeups', '_blank', 'noopener,noreferrer')
                        if (newWindow) newWindow.opener = null
                        window.location.replace("/");
                    }
                );
            }}/>
            <Route path={`${APP_PREFIX_PATH}/patreon`} component={() => {
              ReactGA.outboundLink(
                  {
                      label: 'Visited Patreon.'
                  },
                  function () {
                      const newWindow = window.open('https://www.patreon.com/hashmob', '_blank', 'noopener,noreferrer')
                      if (newWindow) newWindow.opener = null;
                      window.location.replace("/");
                  }
              );
            }}/>
            <Route path={`${APP_PREFIX_PATH}`} render={props => (<Other {...props}/>)}/>
        </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
