import React, {useEffect, useState} from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import GoogleAnalytics from './GoogleAnalytics';
import store from './redux/store';
import Views from './views';
import axios from "axios";
import { Spin } from "antd";
import { THEME_CONFIG } from './configs/AppConfig';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { createBrowserHistory } from 'history';
import {userdataAtom, userdataLoadingAtom} from "state_atoms";
import {useRecoilState} from "recoil";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

ReactGA.initialize('UA-204565821-1', {
  // debug: true,
});

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [userDataLoading, setUserDataLoading] = useRecoilState(userdataLoadingAtom);
  useEffect( () => {
    const update_user_session = async () => {
      await axios.get('https://' + window.location.hostname + '/api/v2/user/details', {withCredentials: true})
          .then(response => {
            if (response.data.hasOwnProperty('display_name')) {
                if(userData !== response.data) {
                    setUserData(response.data);
                    setUserDataLoading(false);
                    setLoading(false);
                }
            } else {
              setUserData(false);
                setLoading(false);
                setUserDataLoading(false);
            }
          }).catch(error => {
              console.log(error.data);
              setUserDataLoading(false);
              setLoading(false);
          })
    }
    update_user_session();
  }, []);

    return (
        <Spin spinning={loading} size={'large'}>
            <div className="App">
                <Provider store={store}>
                    <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                        <Router history={history}>
                            <Switch>
                                <Route path="/" component={Views}/>
                            </Switch>
                            <GoogleAnalytics />
                        </Router>
                    </ThemeSwitcherProvider>
                </Provider>
            </div>
        </Spin>
    );
}

export default App;
