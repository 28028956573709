import antdRuRU from 'antd/es/locale/ru_RU';
import ruMsg from "../locales/ru_RU.json";

const ruLang = {
    antd: antdRuRU,
    locale: 'ru-RU',
    messages: {
        ...ruMsg
    },
};
export default ruLang;
