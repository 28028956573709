import React, { useState, useEffect } from 'react';
import {Menu, Dropdown, Badge, Avatar, List, Button, message, Col, Row} from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import {Link} from "react-router-dom";
import axios from "axios";
import {userdataAtom} from "../../state_atoms";
import {useRecoilState} from "recoil";
import utils from "../../utils";

const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [userData, setUserData] = useRecoilState(userdataAtom);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  useEffect(() => {
      if(userData) {
          setUnreadNotifications(userData['unread_notifications']);
      }
  }, [userData])

  async function mark_all_as_unread() {
      await axios.get('https://' + window.location.hostname + '/api/v2/user/notifications/unread/read', {
          withCredentials: true,
          headers: {
              'Content-Type': 'application/json'
          }
      }).then(response => {
          message.success("All notifications marked as read.", 3)
          setUnreadNotifications([]);
      }).catch(error => {
          message.error("Could not mark notifications as read.", 3)
      })
  }

  const colors = {
      "INFO": "#6392ec",
      "SUCCESS": "#3fb47d",
      "WARNING": "#ea9d40",
      "ERROR": "#d8685b"
  }

  const getNotificationBody = list => {
    return list.length > 0 ?
        <Row>
          <Col span={24}>
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={list}
              renderItem={item => (
                <List.Item className="list-clickable">
                  <Col span={3}>
                    <Avatar style={{ backgroundColor: colors[item.severity], verticalAlign: 'middle'}} size={'small'}>
                      {item.severity[0]}
                    </Avatar>
                  </Col>
                  <Col span={16}>
                    <span className="text-dark">{item.details}</span>
                    {/*<span className="text-gray-light">{}</span>*/}
                  </Col>
                  <Col span={5} flex="auto" style={{textAlign: "right"}}>
                    <small className="mr-auto" style={{minWidth: '60px'}}>{utils.formatDateOnly(item.created_at)}<br/>{utils.formatTimeOnly(item.created_at)}</small>
                  </Col>
                </List.Item>
              )}
          />
          </Col>
        </Row>
        :
        <>
      <div className="empty-notification">
        <img src="/img/others/microphone.svg" alt="empty" />
        <p className="mt-3">No new notifications</p>
      </div>
      <div className="nav-notification-footer">
          <Link to={'/notifications'}>View all</Link>
      </div></>
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification" style={{width: '400px'}}>
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button type="link" onClick={() => mark_all_as_unread()} size="small">Clear </Button>
      </div>
      <div className="nav-notification-body">
          {getNotificationBody(unreadNotifications)}
      </div>
      {
          unreadNotifications.length > 0 ?
            <div className="nav-notification-footer">
          <Link to={'/notifications'}>View all</Link>
        </div>
        :
        null
      }
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal" selectedKeys={[]}>
        <Menu.Item key={1}>

          {unreadNotifications.length > 0 ?
          <Badge count={userData['unread_notifications'].length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge> : <BellOutlined className="nav-icon mx-auto" type="bell" />}
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
