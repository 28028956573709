import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {Layout, Menu, Badge, Col, message} from "antd";
import {
  NotificationOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SolutionOutlined, CheckOutlined
} from '@ant-design/icons';
import Logo from './Logo';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavNotification  from './NavNotification';
import NavSearch  from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils'
import lang from "../../assets/data/language.data.json";
import {Link} from "react-router-dom";
import IntlMessage from "../util-components/IntlMessage";
import NumberFormat from "react-number-format";
import StatisticWidget from "../shared-components/StatisticWidget";
import axios from "axios";
import {userdataAtom} from "../../state_atoms";
import {useRecoilState} from "recoil";

const { Header } = Layout;

export const HeaderNav = props => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction, userlocale} = props;
  const [searchActive, setSearchActive] = useState(false)
  const [userData, setUserData] = useRecoilState(userdataAtom);
  const [statsData, setStatsData] = useState([{
    title: <IntlMessage id="title.hash_count"/>,
    value: 0,
    status: 0,
    subtitle: <IntlMessage id="subtext.compared_last_month"/>
  },
    {
      title: <IntlMessage id="title.newly_found"/>,
      value: 0,
      status: 0,
      subtitle: <IntlMessage id="subtext.compared_last_month"/>
    }]);
  const componentMounted = useRef(true);

  const onSearchActive = () => {
    setSearchActive(true)
  }

  const onSearchClose = () => {
    setSearchActive(false)
  }

  const onToggle = () => {
    if(!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = ()=> {
    if(!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if(isNavTop || isMobile) {
      return '0px'
    }
    if(navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {
    if(!isMobile) {
      onSearchClose()
    }

    const load_stats = async () => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin'
      };


      axios.get('https://' + window.location.hostname + '/api/v2/statistics', {withCredentials: true}).then(response => {
        if(componentMounted.current) {
          setStatsData([{
            title: <IntlMessage id="title.hash_count"/>,
            value: response['data']['hash_count'],
            status: response['data']['difference_hash_count'],
            subtitle: <IntlMessage id="subtext.compared_last_month"/>
          },
          {
            title: <IntlMessage id="title.newly_found"/>,
            value: response['data']['found_last_30_days'],
            status: response['data']['difference_newly_found'],
            subtitle: <IntlMessage id="subtext.compared_last_month"/>
          }]);
        }
      }).catch(function(error) {
        console.log(error.response);
      });
    }
    load_stats();
    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    }
  }, [])

  return (
    <Header className={`app-header ${navMode}`} style={{backgroundColor: headerNavColor}}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode}/>
        <div className="nav" style={{width: `calc(100% - ${getNavWidth()})`}}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && isMobile ?
                    null
                    :
                    <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                      {navCollapsed || isMobile && <MenuUnfoldOutlined className="nav-icon" />
                          // : <MenuFoldOutlined className="nav-icon" />
                      }
                    </li>
              }
              {/*{*/}
              {/*  isMobile ?*/}
              {/*      <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onSearchActive()}}>*/}
              {/*        <SearchOutlined />*/}
              {/*      </li>*/}
              {/*      :*/}
              {/*      <li className="ant-menu-item ant-menu-item-only-child" style={{cursor: 'auto'}}>*/}
              {/*        <SearchInput mode={mode} isMobile={isMobile} />*/}
              {/*      </li>*/}
              {/*}*/}
              {!isMobile &&
                <li className="ant-menu-item ant-menu-item-only-child">
                  <b>New Hashes last 30 days:</b> <NumberFormat
                      displayType={'text'}
                      value={statsData[1]['value']}
                      thousandSeparator=" "
                  />
                </li>
              }
            </ul>
          </div>
          <div className="nav-right">
            <NavLanguage userlocale={userlocale}/>
            {userData &&
              <>
                <Menu mode="horizontal" style={{minWidth: '152px'}} selectedKeys={[]}>
                  <Menu.Item key={2}>
                    <Link to={'/hashlists/personal'}>
                      <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center'}}>
                        <SolutionOutlined className="nav-icon ml-0 mr-2"/>
                        Your Hashlists
                      </div>
                    </Link>
                  </Menu.Item>
                </Menu>
                <NavNotification />
              </>
            }
            <NavProfile/>
            {/*<NavPanel direction={direction} />*/}
          </div>
          {/*<NavSearch active={searchActive} close={onSearchClose}/>*/}
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } =  theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav);