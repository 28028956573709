import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'
import {message} from "antd";

function fetchData(payload) {
	const data = {"email": payload.email, "password": payload.password}
	return  fetch("https://" + window.location.hostname + "/api/v2/authenticate", {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
		credentials: "same-origin"
	})
		.then(res => res.json() )
		.then(data => ({ data }) )
		.catch(ex => {
			console.log('parsing failed', ex);
			return ({ ex });
		});
}

function registerWithData(payload) {
	const {email, password, confirm, display_name, captcha} = payload;
	const data = {
		"email": email,
		"password": password,
		"password_confirm": confirm,
		"display_name": display_name,
		"captcha": captcha
	}
	return  fetch("https://" + window.location.hostname + "/api/v2/user", {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data),
		credentials: "same-origin"
	})
		.then(res => res.json() )
		.then(data => ({ data }) )
		.catch(ex => {
			console.log('parsing failed', ex);
			return ({ ex });
		});
}

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
	  const { data, ex } = yield call(fetchData, payload);
	  if (data) {
		  if(data.hasOwnProperty("error_message")) {
			  localStorage.removeItem(AUTH_TOKEN);
			  yield put(showAuthMessage(Object.values(data.error_message)[0]));
		  }
		  if(data.hasOwnProperty("uuid")) {
			  localStorage.setItem(AUTH_TOKEN, data.uuid);
			  yield put(authenticated(data.uuid));
		  }
	  }
	  else {
		  yield put(showAuthMessage(ex));
	  }
	});
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({payload}) {
		const {data, ex} = yield call(registerWithData, payload);
		if (data) {
			if (data.hasOwnProperty("error_message")) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(showAuthMessage(Object.values(data.error_message)[0]));
			}
			else {
				console.log("Signup Success");
				yield put(signUpSuccess());
			}
		} else {
			yield put(showAuthMessage(ex));
		}
		//
		// 	try {
		// 		const user = yield call(FirebaseService.signUpEmailRequest, email, password);
		// 		if (user.message) {
		// 			yield put(showAuthMessage(user.message));
		// 		} else {
		// 			localStorage.setItem(AUTH_TOKEN, user.user.uid);
		// 			yield put(signUpSuccess(user.user.uid));
		// 		}
		// 	} catch (error) {
		// 		yield put(showAuthMessage(error));
		// 	}
		// }
		// );
	});
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
