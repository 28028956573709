import React, {useEffect, useState} from "react";
import {Menu, Dropdown, Avatar, Button, Modal} from "antd";
import { connect } from 'react-redux'
import {
  PieChartOutlined,
  LogoutOutlined 
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import {Link} from "react-router-dom";
import IntlMessage from "../util-components/IntlMessage";
import axios from "axios";
import {userdataAtom, userdataLoadingAtom} from "../../state_atoms";
import {useRecoilState} from "recoil";

const menuItem = [
    {
        title: "Account Details",
        icon: PieChartOutlined ,
        path: "/user/info"
    },
]

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

export default function NavProfile() {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [userData, setUserData] = useRecoilState(userdataAtom);
    const [userDataLoading, setUserDataLoading] = useRecoilState(userdataLoadingAtom);

    useEffect( () => {
        if(!userDataLoading) {
            const patreon_advertisement_shown = (document.cookie.match(/^(?:.*;)?\s*patreon_advertisement_shown\s*=\s*([^;]+)(?:.*)?$/)||[,null])[1]
            if(!patreon_advertisement_shown) {
                if (userData) {
                    setModalVisible((!userData['patreon_rank'] || userData['patreon_advertisement']));
                } else {
                    setModalVisible(true);
                }
            }
        }
    }, [userData, userDataLoading]);

    async function doSignOut() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin'
        };
        await fetch('https://' + window.location.hostname + '/api/v2/user/logout', requestOptions);
        window.location.reload(false);
    }

    async function markModalAsSeen() {
        await axios.get('https://' + window.location.hostname + '/api/v2/user/patreon_ad_seen', {withCredentials: true})
            .then(r => {});
    }

    const handleOk = () => {
        markModalAsSeen().then(r => {});
        setModalVisible(false);
    };

    function profileMenu(user, color) {
        return (
            <>
                <div className="nav-profile nav-dropdown">
                    <div className="nav-profile-header">
                        <div className="d-flex">
                            {/*<Avatar size={45} src={profileImg}/>*/}
                            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size={45}>
                                {user.display_name.substring(0,2)}
                            </Avatar>
                            <div className="pl-3">
                                <h4 className="mb-0">{user.display_name}</h4>
                                <span className="text-muted">
                                    Rank: Awesome
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="nav-profile-body">
                        <Menu style={{boxShadow: 'none'}}>
                            {menuItem.map((el, i) => {
                                return (
                                    <Menu.Item key={i}>
                                        <Link to={el.path}>
                                            <Icon className="mr-3" type={el.icon}/>
                                            <span className="font-weight-normal">{el.title}</span>
                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                            <Menu.Item key={menuItem.length + 1} onClick={e => doSignOut()}>
                                <span>
                                  <LogoutOutlined className="mr-3"/>
                                  <span className="font-weight-normal"><IntlMessage id="title.sign_out"/></span>
                                </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            </>
        );
    }

    function renderAuthenticatedBox () {
        const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
        const color = colorList[Math.floor(Math.random() * colorList.length)]
        if(userData !== false) {
            return(
                <Dropdown placement="bottomRight" overlay={profileMenu(userData, color)} trigger={["click"]}>
                    <Menu className="d-flex align-item-center" mode="horizontal">
                        <Menu.Item key={1}>
                            <Avatar style={{ backgroundColor: color, verticalAlign: 'middle' }} size={45}>
                                {userData.display_name.substring(0,2)}
                            </Avatar>
                        </Menu.Item>
                    </Menu>
                </Dropdown>
            );
        } else {
            return(
                <Link to="/auth/login" className="btn btn-primary">
                    <Button type="primary" size="small"><IntlMessage id="title.login"/></Button>
                </Link>
            );
        }
    }

    function renderPatreonBox () {
        if (userData) {
            if(userData["patreon_rank"]) return;
            return (
                <Modal title="Consider supporting us!"
                       visible={modalVisible}
                       onOk={handleOk}
                       onCancel={handleOk}
                       footer={[
                           <a href="https://hashmob.net/discord" target="_blank" rel="noreferrer" style={{marginRight: "10px"}}><Button>Join Discord</Button></a>,
                           <a href="https://hashmob.net/patreon" target="_blank" rel="noreferrer" style={{marginRight: "10px"}}>
                               <Button type={"success"} onClick={handleOk}>Support us!</Button>
                           </a>,
                           <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                               Close
                           </Button>
                       ]}>
                    <p>Thank you for using the website {userData.displayName}! Consider joining our discord!</p>
                    <p>We are maintained by our awesome Patreons. Without them, offering this service would not be possible. Please consider supporting us on Patreon by clicking the "Support Us!" button.</p>
                    <p>While supporting us, you will no longer receive this popup.</p>
                </Modal>
            )
        } else {
            return (
                <Modal title="Consider supporting us!"
                       visible={modalVisible}
                       onOk={handleOk}
                       onCancel={handleOk}
                       footer={[
                           <a href="https://hashmob.net/discord" target="_blank" rel="noreferrer"
                              style={{marginRight: "10px"}}><Button>Join Discord</Button></a>,
                           <a href="https://hashmob.net/patreon" target="_blank" rel="noreferrer"
                              style={{marginRight: "10px"}}>
                               <Button type={"success"} onClick={handleOk}>Support us!</Button>
                           </a>,
                           <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                               Close
                           </Button>
                       ]}>
                    <p>Thank you for using the website! We are a community driven service. Consider making an account and joining our discord!</p>
                    <p>We are maintained by our awesome Patreons. Without them, offering this service would not be possible. Please consider supporting us on Patreon by clicking the "Support Us!" button.</p>
                    <p>While supporting us & logged in, you will no longer receive this popup.</p>
                </Modal>
            )
        }
    }

    return (
        <>
            {renderPatreonBox()}
            {renderAuthenticatedBox()}
        </>
    );
}

//
// export default connect(null, {signOut})(NavProfile)
