const dev = {
	API_ENDPOINT_URL: 'https://192.168.0.41:8443/api/v2/'
};

const prod = {
    API_ENDPOINT_URL: 'https://' + window.location.hostname + '/api/v2/'
};

const test = {
    API_ENDPOINT_URL: 'https://192.168.0.41:8443/api/v2/'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
